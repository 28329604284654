import { useQuery } from '@sixfold/app-data-framework';
import { useErrorHandler } from '@sixfold/app-utils';
import { Localized } from '@sixfold/localization-component';
import { CustomError, isNil } from '@sixfold/typed-primitives';
import * as React from 'react';
import { useSetRecoilState } from 'recoil';

import { PRODUCT_NAME } from '../../../constants';
import { ViewerCompanyQuery, ViewerCompanyQueryVariables } from '../../lib/graphql';
import { companyDefaultDwellTimeThresholdState, userInCompanyConfigurationState } from '../atoms';
import { Company, CompanyPermissions, UserInCompany, UserOnTransporeon } from '../entities';
import { viewerCompany } from '../graphql';
import { getCompanyPermissions } from '../lib/entities';

export type ViewerCompany = {
  company: Company;
  permissions: CompanyPermissions;
  userInCompany: UserInCompany | null;
  userOnTransporeon: UserOnTransporeon | null;
};

export function useViewerCompanyQuery(companyId: string): {
  loading: boolean;
  error?: { type: 'not-found' | 'unknown'; message?: React.ReactNode };
  data?: ViewerCompany;
} {
  const setCompanyDefaultDwellTimeThreshold = useSetRecoilState(companyDefaultDwellTimeThresholdState);
  const setUserInCompanyConfiguration = useSetRecoilState(userInCompanyConfigurationState);
  const result = useQuery<ViewerCompanyQuery, ViewerCompanyQueryVariables>(viewerCompany, {
    variables: { company_id: companyId },
    onCompleted: (result) => {
      setCompanyDefaultDwellTimeThreshold(result.viewer?.company?.defaultVehicleDwellTimeThresholdMinutes ?? undefined);
      setUserInCompanyConfiguration(result.viewer?.company?.me?.configuration);
    },
  });

  const errorHandler = useErrorHandler();

  const viewer = result.data?.viewer;
  const company = viewer?.company;

  const permissions = React.useMemo(() => {
    if (isNil(company)) {
      return undefined;
    }

    return getCompanyPermissions({
      is_carrier: company.is_carrier,
      is_shipper: company.is_shipper,
      arePlacesEnabled: company.arePlacesEnabled,
      isOceanUIEnabled: company.isOceanUIEnabled,
      isAirUIEnabled: company.isAirUIEnabled,
      isInvitationFlowDisabled: company.isInvitationFlowDisabled,
      shouldSeeUpcomingTransportsTab: company.shouldSeeUpcomingTransportsTab,
      isMapVehicleLocationsEnabled: company.isMapVehicleLocationsEnabled,
      isGlobalAllocationEnabled: company.settings.allowAllocatingFromGlobalPool,
    });
  }, [company]);

  const mappedCompany: Company | undefined = React.useMemo(() => {
    if (isNil(company)) {
      return undefined;
    }
    const isShipper = company.is_shipper === true;
    const isCarrier = company.is_carrier === true;

    return {
      company_id: company.company_id,
      company_name: company.company_name,
      is_carrier: isCarrier,
      is_shipper: isShipper,
      onboardingStatus: company.onboardingStatus,
      isTrimbleVisibilityCustomer: company.isTrimbleVisibilityCustomer,
      featureFlags: {
        enable_voyage_id_support: company.isVoyageIdSupportEnabled,
      },
    };
  }, [company]);

  if (result.loading) {
    return { loading: true };
  }

  if (isNil(company) || isNil(mappedCompany) || isNil(permissions)) {
    const isUnauthenticated =
      result.error?.networkError &&
      'statusCode' in result.error.networkError &&
      result.error.networkError.statusCode === 401;
    if (!isUnauthenticated) {
      errorHandler?.captureError(
        new CustomError('Failed to fetch a company for permissions check', {
          metadata: { company_id: companyId, data: result.data },
        }),
      );
    }

    return {
      loading: false,
      error: { type: 'not-found' },
    };
  }

  if (company?.isUIDisabled) {
    errorHandler?.captureError(
      new CustomError('Trying to access company which has disabled UI', {
        metadata: { company_id: companyId },
      }),
    );

    return {
      loading: false,
      error: {
        type: 'unknown',
        message: (
          <Localized id="company.disabledUI.error.message">
            This company does not have access to the {{ PRODUCT_NAME }}
          </Localized>
        ),
      },
    };
  }

  return {
    loading: false,
    error: undefined,
    data: {
      company: mappedCompany,
      permissions,
      userInCompany: company.me ?? null,
      userOnTransporeon: company.me?.transporeonPlatform ?? null,
    },
  };
}

export const ViewerCompanyContext = React.createContext<ViewerCompany | null>(null);

export const useViewerCompany = () => {
  const viewerCompany = React.useContext(ViewerCompanyContext);

  if (viewerCompany === null) {
    throw new Error('useViewerCompany must be used within a ViewerCompanyContext');
  }

  return viewerCompany;
};
